export const getFreedom24DirectUrl = (lang: string) => {
  switch (lang) {
    case 'nl':
      return 'https://lp.freedom24.com/nl/welcome?utm_source=2584&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2584&s_id=nlwelcome';
    case 'fr':
      return 'https://lp.freedom24.com/fr/welcome?utm_source=2584&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2584&s_id=frwelcome';
    case 'lt':
      return ' https://lp.freedom24.com/lt/welcome?utm_source=2584&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2584&s_id=ltwelcome';
    case 'it':
      return 'https://lp.freedom24.com/it/welcome?utm_source=2584&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2584&s_id=itwelcome';
    case 'es':
      return 'https://lp.freedom24.com/es/welcome?utm_source=2584&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2584&s_id=eswelcome';
    case 'de':
      return 'https://lp.freedom24.com/de/welcome?utm_source=2584&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2584&s_id=dewelcome';
    case 'el':
      return 'https://lp.freedom24.com/el/welcome?utm_source=2584&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2584&s_id=elwelcome';
    case 'pt':
      return 'https://lp.freedom24.com/pt/welcome?utm_source=2584&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2584&s_id=ptwelcome';
    default:
      return 'https://lp.freedom24.com/en/welcome?utm_source=2584&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2584&s_id=enwelcome';
  }
};
