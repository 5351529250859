export const enum AnalyticsEvent {
  openDeposit = 'openDeposit',
  toggleDetails = 'toggleDetails',
  openOnline = 'openOnline',
  openOnlineModal = 'openOnlineModal',
  openOnlineModalRedirect = 'openOnlineModalRedirect',
  openOnlineModalClose = 'openOnlineModalClose',
  moreInfo = 'moreInfo',
  visitBankSite = 'visitBankSite',
  // search events
  openResidency = 'openResidency',
  openCurrency = 'openCurrency',
  openTerms = 'openTerms',
  openType = 'openType',
  openCashback = 'openCashback',
  openAnnualFee = 'openAnnualFee',
  clickFindDepositButton = 'clickFindDepositButton',
  // how it works button
  clickLearnMore = 'clickLearnMore',
  // show details for deposit
  clickCheckDetails = 'clickCheckDetails',
  clickShowAllDeposits = 'clickShowAllDeposits',
  clickSecurityDeposit = 'clickSecurityDeposit',
  toggleFAQ = 'toggleFAQ',
  toggleFAQLearnMore = 'toggleFAQLearnMore',
  clickHowToChoose = 'clickHowToChoose',

  // multibanking-platform
  platformClickGetStarted = 'platformClickGetStarted',

  clickGetStartedOrOpenOnline = 'clickGetStartedOrOpenOnline',

  clickSingUpForFree = 'clickSingUpForFree',
  clickLogin = 'clickLogin',
  clickLogout = 'clickLogout',
  clickSignUp = 'clickSignUp',
  clickSignUpWithEmail = 'clickSignUpWithEmail',

  // auth modal
  authModalEmailSubmitted = 'authModalEmailSubmitted',
  authModalRecaptchaCompleted = 'authModalRecaptchaCompleted',

  // footer
  footerClickImprint = 'footerClickImprint',
  footerClickBlog = 'footerClickBlog',

  footerClickHome = 'footerClickHome',
  footerClickAbout = 'footerClickAbout',
  footerClickFAQ = 'footerClickFAQ',
  footerClickRegister = 'footerClickRegister',

  footerClickTermsUse = 'footerClickTermsUse',
  footerClickTermsService = 'footerClickTermsService',
  footerClickPrivacy = 'footerClickPrivacy',

  // compliance
  basicInformationStepCompleted = 'basicInformationStepCompleted', // step 1
  addressStepCompleted = 'addressStepCompleted', // step 2
  additionalInformationStepCompleted = 'additionalInformationStepCompleted', // step 3
  sumsubCompleted = 'sumsubCompleted', // TODO: sumsub completed
  smsVerificationCompleted = 'smsVerificationCompleted', // sms verification
  complianceFullCompleted = 'complianceFullCompleted', // all verification passed and user redirected to success redirect url / to profile

  // mintos ads
  mintosClickOpenOnline = 'mintosClickOpenOnline',
  mintosClickShare = 'mintosClickShare',
  mintosBlockView = 'mintosBlockView',

  // freedom24 ads
  freedom24BlockView = 'freedom24BlockView',
  freedom24ClickOpenOnline = 'freedom24ClickOpenOnline',
  freedom24ClickShare = 'freedom24ClickShare',

  // lidion ads
  lidionBlockView = 'lidionBlockView',
  lidionClickOpenOnline = 'lidionClickOpenOnline',
  lidionClickShare = 'lidionClickShare',
}

export const enum AnalyticsCategory {
  main = 'mainPage',
  deposit = 'depositPage',
  compliance = 'compliance',
  savingsAccountsList = 'savingsAccountsList',
  auth = 'auth',
  multibankingPlatform = 'multibankingPlatform',
}

// Analytics event data model
export interface IAnalyticEvent {
  // action as click to set in ga
  action: AnalyticsEvent;
  // category as group for similar events
  category?: AnalyticsCategory;
  // label as name events in ga
  label?: string;
  // value as boolean state for ga
  value?: number;
  // payload as additional data
  payload?: any;
}

// Analytic service interface
export interface IAnalyticService {
  // Init analytic service
  init(): void;

  sendEvent(event: IAnalyticEvent): Promise<void>;
}
