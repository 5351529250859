import dynamic from 'next/dynamic';
import React, { useEffect } from 'react';

import { useAuthModalsControl, useCookiesStore } from '@/store';
import { isMobileApp } from '@/utils/isMobileApp';

const CookiesPopup = dynamic(() => import('./+Cookies'), { ssr: false });

export const Cookies = () => {
  const { dialogCookies, setDialogCookies } = useCookiesStore((state) => state);
  const { isOpenModal: isAuthModalOpen } = useAuthModalsControl(
    (state) => state,
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (localStorage.getItem('cookies') === 'true') {
        setDialogCookies(false);
      } else if (localStorage.getItem('cookies') === 'false') {
        setDialogCookies(false);
      } else if (!isMobileApp()) {
        if (isAuthModalOpen) {
          setDialogCookies(false);
        } else {
          setDialogCookies(true);
        }
      }
    }, 10);
    return () => {
      clearTimeout(timeout);
    };
  }, [isAuthModalOpen]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{dialogCookies && <CookiesPopup />}</>;
};
